.border-3 {
    border-width:3px !important;
}
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 250px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 10px;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}
.text-very-small {
    font-size: 9px !important;
}
.text-medium-small {
    font-size: 11px !important;
}